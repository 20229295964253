.profile-page {
  max-width: fit-content;
  margin-inline: auto;
}

.profile-title {
}

.progress-part {
  display: flex;
  flex-direction: row;
}

.profile-info {
  display: flex;
  flex-direction: row;
}

.ant-progress-bg {
  height: 40px !important;
}
